// Entry point for the build script in your package.json

import "./vendor/jquery"

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  Stimulus.register(controller.name.replace("controllers--", ""), controller.module.default)
})

import "./channels/**/*_channel.js"

Rails.start()
ActiveStorage.start()
Turbo.session.drive = false

import 'fomantic-ui/dist/semantic'

import 'particles.js'
import moment from 'moment'
window.moment = moment

import EasyMDE from 'easymde/dist/easymde.min'
window.EasyMDE = EasyMDE

import hljs from 'highlight.js/lib/core'
import xml from 'highlight.js/lib/languages/xml'
import json from 'highlight.js/lib/languages/json'
window.hljs = hljs
hljs.registerLanguage('xml', xml)
hljs.registerLanguage('json', json)

import 'cocoon-rails'

import "daterangepicker"

import "./components/**/*"
import "./src/**/*"
