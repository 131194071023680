document.addEventListener("turbo:load", function() {
  if ($("#search").length > 0) {
    set_search_form();
    $("#search_input").click(() => $('.ui.accordion.search').accordion("toggle", 0))
    $("#search_input").focus()

    $("#search_input").keyup(function(event) {
      if (check_search_inputs(event)) {
        return;
      }
      delay((function() {
        perform_search();
        return false;
      }), 1500);
      return false;
    })

    $(".search-field").change(() => perform_search())

    var delay = (function() {
      let timer = 0;
      return function(callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
      };
    })()

    $("#date_search").daterangepicker({
      autoUpdateInput: false,
      format: 'DD.MM.YYYY',
      showDropdowns: true,
      opens: "left",
      startDate: $('#date_start').val(),
      endDate: $('#date_end').val(),
      locale: {
        format: 'DD.MM.YYYY',
        firstDay: 1,
        cancelLabel: 'Clear',
      }
    })

    $("#date_search").on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'))
      perform_search()
  });

  $("#date_search").on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('')
        perform_search();
    });
    
    perform_search()
  }
});
        
var check_search_inputs = function(event) {
  if (event.keyCode === 16) {
    return true;
  } else if (event.keyCode === 17) {
    return true;
  } else if (event.keyCode === 18) {
    return true;
  } else if (event.keyCode === 91) {
    return true;
  } else if (event.keyCode === 93) {
    return true;
  } else if (event.keyCode === 37) {
    return true;
  } else if (event.keyCode === 38) {
    return true;
  } else if (event.keyCode === 39) {
    return true;
  } else if (event.keyCode === 40) {
    return true;
  } else {
    return false;
  }
};

var perform_search = function() {
  $("#search_input").parent().addClass('loading');
  return $.ajax({
    url: "/statistics",
    dataType: "script",
    data: collect_search(),
    complete() {
      return $("#search_input").parent().removeClass('loading');
    }
  });
};

var collect_search = function() {
  const search_params = {};
  $('.search-field').each(function(index, element) {
    if ($(element).hasClass('dropdown')) {
      element = $(element).children().first();
    }
    const id = $(element).attr("id");
    const value = $(element).val();
    return search_params[id] = value;
  });
  return search_params;
};

var set_search_form = function() {
  $.each($('.search-field'), function(i) {
    let param = getUrlParameter(this.id);
    if ((param !== undefined) && (param !== "")) {
      if ($('#'+this.id).is(':checkbox')) {
        let left;
        param = (left = param === "true") != null ? left : {true : false};
        return $('#'+this.id).prop("checked", param);
      } else {
        return $('#'+this.id).val(param.replace(/\+/g, ' '));
      }
    }
  });
};

var getUrlParameter = function(sParam) {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  let i = 0;
  while (i < sURLVariables.length) {
    const sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1];
    }
    i++;
  }
};
