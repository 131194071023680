document.addEventListener("turbo:load", function() {
  if ($("#doc_release_date").length) {
    $("#doc_release_date").daterangepicker({
        format: 'DD.MM.YYYY HH:mm',
        singleDatePicker: true,
        showDropdowns: true
      });

    $("#docs").find(".reference-text").each((index, element)  => {
      let simplemde;
      return simplemde = new SimpleMDE({element: $(element)[0]});
    });

    return $("#docs").on("cocoon:after-insert", function(e, project_to_be_added) {
      let simplemde;
      $('.ui.dropdown').dropdown({placeholder: false});
      return simplemde = new SimpleMDE({element: $(project_to_be_added).find(".reference-text")[0]});
    });
  }
});
      