document.addEventListener("turbo:load", function() {
  if ($("#output").length) {
    if ($('.item.sample1').length) {
      $('.item.sample1').on('click', function() {
        if ($(".asset-id").data("asset-id") !== "") {
          $("#request_uri").val("/assets/" + $(".asset-id").data("asset-id") + "?token=" + $("#request_uri").data("token"));
        } else {
          $("#request_uri").val("/assets/192267?token=" + $("#request_uri").data("token"));
        }
        get_request();
      });
    }

    if ($('.item.sample2').length) {
      $('.item.sample2').on('click', function() {
        const date = new Date();
        date.setDate(date.getDate() - 5);

        let m = date.getMonth() + 1;
        let d = date.getDate();

        m = m > 9 ? m : '0' + m;
        d = d > 9 ? d : '0' + d;

        const x = d + '.' + m + '.' + date.getFullYear();

        $("#request_uri").val("/assets/delta/" + x + "?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    if ($('.item.sample3').length) {
      $('.item.sample3').on('click', function() {
        $("#request_uri").val("/assets/?imdb_id=2446042&token=" + $("#request_uri").data("token"));
        get_request();
      });
    }
    if ($('.item.sample15').length) {
      $('.item.sample15').on('click', function() {
        $("#request_uri").val("/assets/?external_id=test&token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    if ($('.item.sample4').length) {
      $('.item.sample4').on('click', function() {
        $("#request_uri").val("/frame_rates?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    if ($('.item.sample5').length) {
      $('.item.sample5').on('click', function() {
        $("#request_uri").val("/asset_types?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    if ($('.item.sample6').length) {
      $('.item.sample6').on('click', function() {
        $("#request_uri").val("/language_codes?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    if ($('.item.sample7').length) {
      $('.item.sample7').on('click', function() {
        $("#request_uri").val("/audio_codecs?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    if ($('.item.sample8').length) {
      $('.item.sample8').on('click', function() {
        $("#request_uri").val("/content_types?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }
      
    if ($('.item.sample9').length) {
      $('.item.sample9').on('click', function() {
        $("#request_uri").val("/aspect_ratios?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }
      
    if ($('.item.sample10').length) {
      $('.item.sample10').on('click', function() {
        $("#request_uri").val("/image_aspect_ratios?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    if ($('.item.sample11').length) {
      $('.item.sample11').on('click', function() {
        $("#request_uri").val("/asset_requests/" + $(".arq-uuid").data("uuid") + "?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    if ($('.item.sample12').length) {
      $('.item.sample12').on('click', function() {
        $("#request_uri").val("/asset_requests/open?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }
      
    if ($('.item.sample13').length) {
      $('.item.sample13').on('click', function() {
        $("#request_uri").val("/dfl/status/068B04210375890F268E8DB9F93AF68E397A4BD9?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }
      
    if ($('.item.sample14').length) {
      $('.item.sample14').on('click', function() {
        $("#request_uri").val("/assets/068B04210375890F268E8DB9F93AF68E397A4BD9?token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    if ($('.item.sample16').length) {
      $('.item.sample16').on('click', function() {
        $("#request_uri").val(`/transfers?token=${$("#request_uri").data("token")}`);
        get_request();
      });
    }

    if ($('.item.sample17').length) {
      $('.item.sample17').on('click', function() {
        $("#request_uri").val(`/transfers/BC0CE2D84C5D4673B2F61F9B12BF1AB2?token=${$("#request_uri").data("token")}`);
        get_request();
      });
    }

    if ($('.item.sample18').length) {
      $('.item.sample18').on('click', function() {
        $("#request_uri").val(`/assets/319655/proxy_audio_tracks?token=${$("#request_uri").data("token")}`);
        get_request();
      });
    }
    
    if ($('.item.sample19').length) {
      $('.item.sample19').on('click', function() {
        $("#request_uri").val(`/assets?token=${$("#request_uri").data("token")}`);
        get_request();
      });
    }

    if ($('.item.sample20').length) {
      $('.item.sample20').on('click', function() {
        $("#request_uri").val(`/assets?with_title=true&page=2&token=${$("#request_uri").data("token")}`);
        get_request();
      });
    }

    if ($('.item.sample21').length) {
      $('.item.sample21').on('click', function() {
        const date = new Date();
        date.setDate(date.getDate());

        let m = date.getMonth() + 1;
        let d = date.getDate();

        m = m > 9 ? m : '0' + m;
        d = d > 9 ? d : '0' + d;

        const x = d + '.' + m + '.' + date.getFullYear();

        $("#request_uri").val("/assets/diff/?date=" + x + "&token=" + $("#request_uri").data("token"));
        get_request();
      });
    }

    $('.button.request').on('click', function() {
      get_request();
    });

    $('#request_uri').keyup(function(event) {
      if (event.keyCode === 13) {
        $('.button.request').click();
      }
    });

    if ($('.item.sample1').length) {
      $('.item.sample1').click();
      return get_request();
    } else if ($('.item.sample4').length) {
      $('.item.sample4').click();
      return get_request();
    } else if ($('.item.sample11').length) {
      $('.item.sample11').click();
      return get_request();
    } else if ($('.item.sample13').length) {
      $('.item.sample13').click();
      return get_request();
    }
  }
});
  
var get_request = function() {
  if ($("#request_uri").val() !== "") {
    
    if (!($("#request_uri").val().indexOf("token=") > -1)) {
      let mode = "?";
      if ($("#request_uri").val().indexOf("?") > -1) {
        mode = "&";
      }
        
      $("#request_uri").val($("#request_uri").val() + mode + "token=" + $("#request_uri").data("token"));
    }
    
    return $.ajax({
      url: $("#request_uri").val(),
      type: "GET",
      dataType: "json",
      beforeSend() {
        $("#output").empty();
        return $("#output").append('<p><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></p><div class="ui active inverted dimmer"><div class="ui loader"></div></div>');
      },
      complete() {},

      success(data, textStatus, xhr) {
        $("#output").empty();
        $("#output").text(JSON.stringify(data, null, 2));
        
        var v = document.getElementById('output'); 
        delete v.dataset.highlighted;
        
        document.querySelectorAll('#output').forEach(el => {
          hljs.highlightElement(el);
        });
      },

      error(jqXHR, textStatus, errorThrown) {
        $("#output").empty();
        return $("#output").append(`<br/><div class='ui negative message'><p>${jqXHR.responseJSON.error}</p></div>`);
      }
    });
  }
};
